<template>
  <div class="d-flex flex-column text-center">
    <v-list class="mx-auto" width="300px" dense>
        <span class="text-overline">{{ $vuetify.lang.t("$vuetify.worksheet.worksheetSummary") }}</span>
        <v-list-item class="mx-8">
          <v-list-item-content>{{ $vuetify.lang.t("$vuetify.base.grossWeight") }}</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ sum.grossWeight | formatNumber }} {{ weightUnit }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="mx-8">
          <v-list-item-content>{{ $vuetify.lang.t("$vuetify.base.netWeight") }}</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ sum.netWeight | formatNumber }} {{ weightUnit }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="mx-8" v-if="worksheetTypeFields.hasTargetWeight">
          <v-list-item-content>{{ $vuetify.lang.t("$vuetify.base.targetWeight") }}</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ worksheet.targetWeight | formatNumber }} {{ weightUnit }}
          </v-list-item-content>
        </v-list-item>
    </v-list>
<hr/>
    <v-subheader class="mx-auto">{{$vuetify.lang.t("$vuetify.worksheet.netWeightBeforeSubmit")}}:</v-subheader>
    <v-text-field
      class="mx-auto mt-8"
      v-model.number="worksheet.netAdjustmentWeight"
      :label="$vuetify.lang.t('$vuetify.base.netAdjustmentWeight')"
      style="max-width: 250px; font-size: 26px"
      outlined
      type="number"
      inputmode="decimal"
      min="0"
      :disabled="submitted"
      @change="
        calculateNetAdjustPercentage();
        $emit('update:worksheet', worksheet);
      "
    >
      <template v-slot:append> {{ weightUnit }} </template>
    </v-text-field>

    <div class="mb-5 text-subtitle-2">
      {{$vuetify.lang.t("$vuetify.base.netAdjustment")}}: {{ netAdjustPercentage | formatNumber }}%
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
    sum: {
      type: Object,
      required: true,
    },
    weightUnit: {
      type: String,
      required: false,
      default: 'Kg'
    }
  },
  data: () => {
    return {
      netAdjustPercentage: 0,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),

    hasEntries() {
      return this.worksheet.entries && this.worksheet.entries.length > 0;
    },
    submitted() {
      return this.worksheet && ["SUBMITTED", "USED"].includes(this.worksheet.status);
    },
    worksheetTypeFields() {
      if (
        this.worksheetTypeDefinitions &&
        this.worksheetTypeDefinitions.length > 0 &&
        this.worksheet.worksheetTypeId
      ) {
        return this.worksheetTypeDefinitions.find(
          (t) => t.id == this.worksheet.worksheetTypeId
        );
      } else {
        return {};
      }
    },
  },
  components: {},
  created() {
        this.fetchWorksheetTypeDefinitions();
  },
  methods: {
    ...mapActions("messages", ["addErrorMessage", "addSuccessMessage"]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),

    calculateNetAdjustPercentage() {
      const netAjustWeight = this.worksheet.netAdjustmentWeight
        ? this.worksheet.netAdjustmentWeight
        : 0;
      this.netAdjustPercentage =
        this.sum && this.sum.netWeight
          ? (netAjustWeight / Math.abs(this.sum.netWeight)) * 100
          : 0;
    },
  },
};
</script>

<style></style>
